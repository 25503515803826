export default async function guest ({ next, store }){

    const accessToken = localStorage.getItem('accessToken')

    if (!accessToken)
    {
        return next()
    }

    await store.dispatch('auth/syncAuth')

    if(store.getters['auth/isAuthenticated']){
        return next({
            name: 'home'
        })
    }

    return next()
}
